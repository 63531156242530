<template>
  <div
    class="multiview-event-drawer-params-tab"
    :class="{ 'simulate-new-prices-active': simulateNewPricesMessageActive }"
  >
    <div
      v-if="isMultiviewParamsTabFrozen"
      class="pricing-loading-overlay"
    >
      <p>Simulating prices - please wait...</p>
    </div>
    <Spinner
      v-if="isMultiviewPlayerParamsLoading"
      small
    />
    <div
      v-if="!isMultiviewPlayerParamsLoading"
      class="multiview-event-drawer-params-tab--container"
    >
      <MultiviewPlayerParamsNewPricesAvailable
        v-if="simulateNewPricesMessageActive"
        @onSimulate="onSimulate"
      />
      <div class="params-header-search-actions">
        <TextInput
          class="search-field"
          :model-value="playerSearch"
          @update:modelValue="updatePlayerSearch"
          icon="search"
          placeholder="Search"
          small
        />
        <Button
          variant="secondary"
          icon="link-external"
          @click="goToParamSetup"
        >
          Param manager
        </Button>
        <Button
          variant="secondary"
          icon="modem"
          :disabled="!isSimulateBtnEnabled || isSimulateBtnFrozen"
          :loading="isSimulateBtnLoading"
          @click="onSimulate"
        >
          <span>Simulate</span>
          <span v-if="isSimulateBtnFrozen">
            ({{ countdownValue }})
          </span>
        </Button>
        <Button
          variant="primary"
          class="publish-btn"
          :class="{ 'disabled': !isPublishBtnEnabled }"
          :icon="'upload'"
          :disabled="!isPublishBtnEnabled"
          :loading="isPublishBtnLoading"
          @click="onPublish"
        >
          Publish
        </Button>
      </div>
      <div
        class="params-header-tabs"
      >
        <div
          :class="[
            'params-header-tabs-item',
            { 'params-header-tabs-item--is-active': activeTab === 'teamB' }
          ]"
          @click="setActiveTab('teamB')"
        >
          {{ playerParams?.teamB?.name }}
        </div>
        <div
          :class="[
            'params-header-tabs-item',
            { 'params-header-tabs-item--is-active': activeTab === 'teamA' }
          ]"
          @click="setActiveTab('teamA')"
        >
          {{ playerParams?.teamA?.name }}
        </div>
      </div>
      <PlayerProps
        v-if="event?.sportId !== BASEBALL_ID && event?.sportId !== FOOTBALL_ID"
        :event="event"
        :data="filteredTeamData"
        :team-label="activeTab"
        @onPropChange="onPlayerPropsUpdate"
        @onPropIncrease="onPlayerPropIncrease"
        @onPropDecrease="onPlayerPropDecrease"
      />
      <BaseballPlayerParamsByTeam
        v-if="event?.sportId === BASEBALL_ID"
        :team-data="filteredTeamData"
        :team-label="activeTab"
        :current-catcher-index-prop="currentCatcherIndex"
      />
      <AmericanFootballPlayerParamsByTeam
        v-if="event?.sportId === FOOTBALL_ID"
        :team-data="filteredTeamData"
        :team-label="activeTab"
      />
    </div>
  </div>
</template>

<script>
import {
  computed, onMounted, ref, watch,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import {
  cloneDeep, find, filter, includes, toLower, orderBy, map,
  set, get,
} from 'lodash';
import TextInput from '@/components/common/TextInput';
import Button from '@/components/common/Button';
import PlayerProps from './params/PlayerProps';
import MultiviewPlayerParamsNewPricesAvailable from './params/MultiviewPlayerParamsNewPricesAvailable';
import BaseballPlayerParamsByTeam from '@/components/player-setup/baseball/BaseballPlayerParamsByTeam';
import AmericanFootballPlayerParamsByTeam from '@/components/player-setup/setup-by-team/american-football/AmericanFootballPlayerParamsByTeam';
import Spinner from '@/components/common/Spinner';
import { getPlayerPropIncreaseDecreaseValue, getLowerLimit, getUpperLimit } from '@/services/helpers/player-setup-mapper';
import sportIds from '@/services/helpers/sports';

const {
  BASEBALL_ID,
  FOOTBALL_ID,
} = sportIds;

export default {
  components: {
    PlayerProps,
    TextInput,
    Button,
    Spinner,
    MultiviewPlayerParamsNewPricesAvailable,
    BaseballPlayerParamsByTeam,
    AmericanFootballPlayerParamsByTeam,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const activeTab = ref('teamA');
    const playerSearch = ref('');
    const playerParams = computed(() => store.getters.multiviewPlayerParams);
    const selectedTeamData = computed(() => {
      const eventDetails = props.event;
      const teamLineup = (activeTab.value === 'teamA' && !eventDetails?.isUSAView)
        || (activeTab.value === 'teamB' && eventDetails?.isUSAView)
        ? eventDetails?.homeLineup : eventDetails?.awayLineup;
      if (playerParams.value) {
        return map(
        playerParams?.value[activeTab.value]?.playerProps,
        (player) => ({
          ...player,
          isInTeamLineup: includes(map(teamLineup, ({ playerId }) => playerId), player.playerId),
        }),
        );
      }
      return [];
    });
    const filteredTeamData = computed(() => orderBy(filter(selectedTeamData.value, (player) => includes(toLower(player.playerName), toLower(playerSearch.value))), 'playerPositionIndex'));
    const isMultiviewPlayerParamsLoading = computed(() => store.getters.multiviewPlayerParamsLoading);
    const simulateNewPricesMessageActive = computed(() => store.getters.multiviewSimulateBySocketChangeEnabled);
    const isSimulateBtnEnabled = computed(() => store.getters.multiviewSimulateBtnEnabled || simulateNewPricesMessageActive.value);
    const isSimulateBtnLoading = computed(() => store.getters.multiviewSimulateBtnLoading);
    const isPublishBtnEnabled = computed(() => store.getters.multiviewSubmitBtnEnabled);
    const isPublishBtnLoading = computed(() => store.getters.multiviewSubmitBtnLoading);
    const isMultiviewParamsTabFrozen = computed(() => store.getters.multiviewDrawerFrozen);
    const isSimulateBtnFrozen = computed(() => store.getters.multiviewSimulateBtnFrozen);
    const countdownValue = ref(10);
    const currentCatcherIndex = computed(() => find(filteredTeamData.value, ({ hitterParams }) => hitterParams?.isCatcher)?.playerIndex);

    const setActiveTab = (teamLabel) => { activeTab.value = teamLabel; };
    const updatePlayerSearch = (val) => { playerSearch.value = val; };

    const onPlayerPropsUpdate = ({
      playerId, param, value,
    }) => {
      const updatedPlayerProps = cloneDeep(playerParams.value);
      const updatedPlayer = find(updatedPlayerProps[activeTab.value].playerProps, { playerId });
      set(updatedPlayer, param, value ? parseFloat(value) : null);
      store.dispatch('updateMultiviewPlayerProps', updatedPlayerProps);
    };

    const onPlayerPropIncrease = ({
      playerId, param,
    }) => {
      const updatedPlayerProps = cloneDeep(playerParams.value);
      const updatedPlayer = find(updatedPlayerProps[activeTab.value].playerProps, { playerId });
      if (get(updatedPlayer, param) >= getUpperLimit(param)) return;
      set(updatedPlayer, param, parseFloat((get(updatedPlayer, param) + getPlayerPropIncreaseDecreaseValue(param)).toFixed(3)));
      store.dispatch('updateMultiviewPlayerProps', updatedPlayerProps);
    };

    const onPlayerPropDecrease = ({
      playerId, param,
    }) => {
      const updatedPlayerProps = cloneDeep(playerParams.value);
      const updatedPlayer = find(updatedPlayerProps[activeTab.value].playerProps, { playerId });
      if (get(updatedPlayer, param) <= getLowerLimit(param)) return;
      set(updatedPlayer, param, parseFloat((get(updatedPlayer, param) - getPlayerPropIncreaseDecreaseValue(param)).toFixed(3)));
      store.dispatch('updateMultiviewPlayerProps', updatedPlayerProps);
    };

    const onSimulate = () => store.dispatch('getPriceOnDemandMultiview');
    const onPublish = () => store.dispatch('submitMultiviewPlayerProps');

    watch(() => isSimulateBtnFrozen.value, (isFrozen) => {
      if (isFrozen) {
        const interval = setInterval(() => {
          countdownValue.value -= 1;
          if (countdownValue.value === 0) {
            store.dispatch('setIsMultiviewSimulateBtnFrozen', false);
            clearInterval(interval);
            countdownValue.value = 10;
          }
        }, 1000);
      }
    });

    const goToParamSetup = () => {
      const routeData = router.resolve({
        name: 'params-setup',
        params: { eventId: props.event.id },
      });
      window.open(routeData.href, '_blank');
    };

    onMounted(() => {
      if (!playerParams.value) {
        store.dispatch('getMultiviewPlayerParams');
      }
    });

    return {
      playerParams,
      filteredTeamData,
      activeTab,
      playerSearch,
      isSimulateBtnEnabled,
      isSimulateBtnLoading,
      isPublishBtnEnabled,
      isPublishBtnLoading,
      isMultiviewParamsTabFrozen,
      isSimulateBtnFrozen,
      countdownValue,
      isMultiviewPlayerParamsLoading,
      simulateNewPricesMessageActive,
      BASEBALL_ID,
      currentCatcherIndex,
      FOOTBALL_ID,
      setActiveTab,
      updatePlayerSearch,
      onPlayerPropsUpdate,
      onPlayerPropIncrease,
      onPlayerPropDecrease,
      onSimulate,
      onPublish,
      goToParamSetup,
    };
  },
};
</script>

<style lang="scss">
  .multiview-event-drawer-params-tab {
    padding: 0 16px 16px 16px;
    height: calc(100% - 64px);
    position: relative;
    overflow: hidden;

    .pricing-loading-overlay {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: $gray300;
      z-index: $modalZIndex;
      p {
        padding: 16px;
        background-color: $white;
        width: 300px;
        border-radius: 4px;
      }
    }

    &--container {
      height: 100%;
    }

    .params-header-search-actions {
      display: flex;
      align-items: center;
      gap: 8px;
      padding-top: 3px;
      padding-bottom: 12px;
      position: sticky;
      top: 0;

      .search-field {
        width: 100%;
      }
    }

    .params-header-tabs {
      display: flex;
      position: sticky;
      top: 0;
      background: var(--neutral-bg-default-active, #FFF);
      z-index: 101;

      &-item {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 32px;
        padding: 0 8px;
        gap: 4px;
        user-select: none;
        cursor: pointer;
        border-bottom: 2px solid var(--primary-stroke-weak-active, #F0F0F0);

        &--is-active {
          border-bottom: 2px solid var(--primary-stroke-weak-active, #003C3C);
        }

        .icon {
          stroke: #191414;
        }
      }
    }
    .baseball-player-params-by-team-root {
      height: calc(100% - 70px);
    }
    &.simulate-new-prices-active {
      .player-props-root {
        height: calc(100% - 64px - 53px);
      }
      .baseball-player-params-by-team-root {
        height: calc(100% - 118px);
      }
    }
  }
</style>
